.carousel-img-banner {
    width: 100%;
    height: 100%;
}

@media screen and (max-width:768px) {
    .carousel-img-banner {
        width: 100%;
        height: 100%;
    }
}

#carousel-div {
    background-color: white;
    color: white;
}

#carousel-item1 {
    background-image: url("/public//img//carousel/banner2_desktop.png");
    background-size: 1350px;
    color: white;
    padding: 16px;
}

@media screen and (max-width:768px) {
    #carousel-item1 {
        background-image: url("/public/img/carousel/banner1_mobile.png");
        background-size: 426px;
        color: white;
        padding: 16px;
    }
}

#carousel-item2 {
    background-image: url("/public/img/carousel/banner2.png");
    color:white;
    padding: 16px;
}

#carousel-item3 {
    background-image: url("/public/img/carousel/banner3.png");
    color: white;
    padding: 16px;
}
