.container-itens {
   display: inline-block;
   vertical-align: top;
   max-width: 900%;
   margin: 0 auto;
   padding: 0 2px;
   width: 25%;
   min-width: 194px;
}
.main-itens {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    /* border: 1px rgb(196, 206, 196) solid; */
    background-color:white;
    border-radius: 4px;
    /* margin-top: 2px; */
    /* margin-bottom: 1px; */
    padding: 0px 1px 0px 0px;
}
.main-itens:hover{
    background-color: rgb(246, 247, 253);
     /* border: 1px rgb(226, 221, 221) solid; */
}

.itens-img {
    margin-top: 6px;
    max-width: 100%;
    width: 10rem;
    height: 11rem;
    border-radius: 6px;
    border:1px solid none;
}
.itens-img:hover{
    max-width: 160%;
    width: 17rem;
    height: 18rem;
    border-radius: 6px;
}

.input-amount{
    margin-bottom: 2px;
}

.select-amount{
    margin-bottom: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
}
