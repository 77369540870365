.header-home{
    border-radius: 3px 3px 0px 0px;
    background-color:black
}

.header-home-carrinho {
    color:white;
    padding: 9px;
    width: 33px;
    height: 32px;
}
.header-home-carrinho:hover{
    background-color:none;
}

.header-home-sub-total {
    /* border: solid 1px rgba(0, 0, 0, 0.455); */
    padding: 9px;
    color:white;
    font-size: 14px;
}
.header-home-sub-total:hover{
    background-color:none;
}


.header-contact{
    font-size: 14px;
    padding: 6px;
    color:white;
    text-decoration: none;
}
.header-contact:hover{
    background-color:none;
    color: grey;
}

@media screen and (max-width:768px) {
    .header-contact{
        font-size: 12px;
    }
}

#header-frete{
    display: inline;
    color: gray;
    font-size: small;
}