#cards-accepted {
    border: 1px solid grey;
    border-radius: 8px;
    padding: 12px;
    margin: 16px;
    font-size: large;
    color: black;
    width: 90%;
}

@media screen and (max-width:768px) {
    #cards-accepted {
        width: 80%;
    }
}