#home-form {
    background-color: rgb(29, 27, 27);
    padding: 18px;
    color: white;;
    border: 2px solid white;
    border-radius: 12px;
}

@media screen and (max-width:768px) {
    #home-form {
        border: 3px solid white;
        border-radius: 8px;
    }
}

#home-form-input input {
    display: flex;
    margin-inline: 12px;
    height: 36px;
    width: 230px;
    border-radius: 8px;
}

#home-form-label label {
    margin-top: 16px;
}

#home-form-button {
    margin: 12px;
    color:whitesmoke;
}