#center {
    text-align: center;
}

#input-valor {
    width: 40%;
    padding: 8px;
    border-radius: 3px;
    margin-top: 3px;
    margin-left: 3px;
}

#header-contas-receber {
    background-color: gray;
    padding: 10px;
    color: aliceblue;
    font-size: large;
}

thead {
    padding: 0px;
    margin: 0px;
    background-color: rgb(91, 91, 93);
    color: white;
    font-size: 14px;
    border: 1px solid white;
}

tr {
    font-size: 12px;
}

td {
    font-size: 12px;
}

.list-person {
    font-size: 12px;
    display: flex;
    max-width: 50%;
    justify-content: center;
}

.list-despesa {
    font-size: 12px;
    display: flex;
    max-width: 50%;
    justify-content: center;
    padding: 12px;
}