.btn-search {
    display: contents;
}

.checkbox-label {
margin-left:4px;
font-size:12px;
}

.search-items-img {
    background-color: white;
    border-radius: 12px;
    position: relative;
    left: -4.6rem;
    top: 0.2rem;
    width: 32px;
    height: 32px;
}

.search-items-img:hover {
    background-color: aliceblue;
}

.search-items-message {
    font-size: 12px;
    padding-left: 12px;
}

.search-select {
    display:block;
    color: black;
    width: 5rem;
    height: auto;
    font-size: 16px;
    border-radius: 8px 0px 0px 8px;
    border: solid 1px gray;
}

.search-item-main {
    margin: 6px;
    left: auto;
    margin-left: 18px;
    justify-content: center;

}

.search-item-input {
    font-size: 15px;
    border-radius: 0px 6px 6px 0px;
    width: 100%;
    height: 36px;
    border: solid 1px gray;
}

.search-item-input:hover{
    border: none;

}