#itensStoreCar {
    display: flex;
    flex-Direction: column;
    align-Items: center;
    margin: 1px;
}

#itensStoreCarImg {
    width: 60px;
    height: 60px;
}
#itensStoreCartogoback {
    display: flex;
    flex-Direction: column;
    align-Items: center;
    margin: 1px
}

#itensStoreCarButton {
    display: inline;
    padding: 0px;
    margin: 0px;
}

#itensStoreCarButton button{
    margin: 1px;
    border-radius: 8px;
    color:white;
    border: 2px solid white;
}