#invoice-header{
    color: auto;
    margin-top: 8px;
    font-size: small;
}

#installments{
    padding: 2px;
    margin: 5px;
    margin-inline: 20px;
    font-size: small;
    color: auto;
}

#persons{
    padding: 2px;
    margin:5px;
    margin-inline:20px;
    font-size: small;
    color:auto;
}