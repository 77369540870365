p a {
    text-decoration: none;
}

footer {
    background-color: rgb(247, 245, 245);
    color: black;
    padding: 12px;
    border-radius: 3px;
}

a:hover {
    color: black;
}

.social-icons {
    background-color: white;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    margin: 4px;
    padding: 2px;
}

.social-icons:hover {
    width: 38px;
    height: 38px;
    border-radius: 8px;
    background-color: gray;
    /* border: 2px solid gray; */
    padding: 2px;
}

.btn-top {
    width: 36px;
    height: 36px;
    float: right;
    border: gray solid 1px;
    border-radius: 12px;
    margin: 0px;
}

.btn-top:hover {
    border: solid 1px rgb(213, 207, 207);
    border-radius: 16px;
}

.site-ssl {
    border-radius: 2px;
    width: 90px;
    height: 38px;
}

.footer-delivery {
    width: 100%;
}

.footer-payment {
    cursor: pointer;
    border: 8px solid white;
    border-radius: 2px;
    width: 320px;
    height: 55px;
}

/* @media screen and (max-width:768px) {} */
.maps {
    width: 100%;
    border-radius: 2px;
    border: 8px solid white;
    background-color: white;
}

.text-footer {
    width: 100%;
    border-radius: 4px;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: white;
}

#header-channel {
    display: flex;
    justify-content: center;
    margin: 1%;
    height: center;
    font-size: 16px;
    line-height: 3;
    color:rgb(56, 54, 54);
}
@media screen and (max-width:768px) {
    #header-channel {
        font-size: 0.8rem;
    }
}

#header-channel-title{
    color: rgb(4, 4, 85);
    margin-bottom: -8px;
}
@media screen and (max-width:768px){
#header-channel-title{
    font-size: 14px
}
}

#header-channel-left {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 16rem;
}

#header-channel-center {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 16rem;
}

#header-channel-right {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 12rem;
}