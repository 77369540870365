#home-main-container {
    background-color: black;
    color:white;
    padding: 0px;
    margin: 0px;
}

#home-main {
    display: flex;
    padding: 0px;
    width: 100%;
}

#home-header {
    padding: 58px;
    background-image: url("/public/img/carousel/banner2.png");
}

@media screen and (max-width:768px) {
    #home-header{
        padding: 18px;
    }
}

#home-header-btn{
    border-radius: 0px;
    padding: 12px;
}


@media screen and (max-width:768px) {
    #home-main {
        display: inline;
    }
}

#home-div1 {
    padding: 18px;
    font-size: 18px;
}

#home-div2 {
    padding: 12px;
}


#home-cards {
    padding: 12px;
}

#home-cards-title {
    font-size: 43px;
    padding: 16px;
}
@media screen and (max-width:768px) {
    #home-cards-title {
        font-size: 22px;
    }
}

#home-cards-content {
    font-size: 18px;
    padding: 12px;
}

#demo-repo-main{
    display: flex;
    padding: 0px;
    width: 100%;
}
@media screen and (max-width:768px) {
    #demo-repo-main{
        display: inline;
    }
}

#home-repo-btn{
    padding: 12px;
    border-radius: 0px;;
}

#home-repo-btn-content{
    margin-top: 6px;
    font-size: small;
}

#home-repo-img{
    width: 530px;
    height: auto;
    border-radius: 8px;
    border: 3px solid gray;
}
@media screen and (max-width:768px) {
    #home-repo-img{
        width: 100%;
    }
}

#home-repo-img-content{
    padding: 2px;
    margin-top: 6px;
    font-size: small;
}

#home-store-main{
    display: flex;
    padding: 0px;
    width: 100%;
}
@media screen and (max-width:768px) {
    #home-store-main{
        display: inline;
    }
}

#home-store-btn{
    padding: 12px;
    border-radius: 0px;
}

#home-store-btn-content{
    font-size: small;
    padding: 6px;
}

#home-store-img{
    width: 530px;
    height: auto;
    border-radius: 6px;
    border: 2px solid white;
}

@media screen and (max-width:768px) {
    #home-store-img{
        width: 100%;
    }
}

#home-store-img-content{
    padding: 2px;
    margin-top: 6px;
    font-size: small;
}